import {
  CompareArrows,
  AdminPanelSettings,
  ManageHistory,
  ChatBubble,
  People,
  QuestionMark,
  FactCheckRounded,
  Settings,
  PowerSettingsNew,
  DashboardRounded,
  MonetizationOnRounded,
  EuroRounded,
  PermIdentityRounded,
} from '@mui/icons-material'
import { styled } from '@mui/material'
import React, { useState } from 'react'
import { Menu, useLocaleState, useTranslate } from 'react-admin'

import { Contract } from 'api/gen'
import { ReactComponent as Logo } from 'assets/logo_white.svg'
import { CanAccess, useCanAccess } from 'libs/auth'
import { intercom, Locale } from 'libs/intercom'
import { useModal } from 'libs/modal'
import { NESSPAY_ONLY_ROLES } from 'resources/types'
import { usePersistedUser } from 'user/context'
import { Row, Spacer } from 'utils/spacing'
import { useRoleTranslation } from 'utils/user'

import { MenuItemLink } from './MenuItemLink'
import { CustomSidebarToggleButton } from './SidebarToggleButton'
import { UserProfile } from './UserProfile'

const MenuSection = styled('div')({
  boxSizing: 'content-box',
  width: '100%',
  paddingTop: 20,
  paddingBottom: 20,
})

const SidebarBottomSection = () => {
  const t = useTranslate()
  const [locale] = useLocaleState()
  const { isSwanActive } = usePersistedUser()

  const [chatOpen, setChatOpen] = useState(false)
  const { openModal: openLogoutModal } = useModal('logout')

  const toggleChat = () => {
    if (!chatOpen) intercom.openChat()
    else intercom.closeChat()
    setChatOpen(!chatOpen)
  }

  return (
    <MenuSection>
      <MenuItemLink
        onClick={intercom.openOnboardingTasks}
        primaryText={t('buttons.getStarted')}
        leftIcon={<FactCheckRounded />}
        disabled={!isSwanActive}
      />
      <MenuItemLink
        onClick={() => intercom.openHelpCenter(locale as Locale, undefined)}
        primaryText={t('buttons.intercom.faq')}
        leftIcon={<QuestionMark />}
      />
      <MenuItemLink
        onClick={toggleChat}
        primaryText={chatOpen ? t('buttons.intercom.open') : t('buttons.intercom.close')}
        leftIcon={<ChatBubble />}
      />
      <MenuItemLink
        onClick={openLogoutModal}
        primaryText={t('buttons.logout.main')}
        leftIcon={<PowerSettingsNew />}
      />
    </MenuSection>
  )
}

const SidebarTopSection = () => {
  const t = useTranslate()
  const { tRole } = useRoleTranslation()
  const { organization, isSwanActive } = usePersistedUser()
  const { canAccess } = useCanAccess()
  const isAdmin = canAccess({ roles: [Contract.RolesEnum.Admin] })

  const hasCompletedOnboarding = organization?.onboarding?.hasCompletedOnboarding ?? false

  return (
    <MenuSection>
      <Row>
        <CustomSidebarToggleButton />
        <Spacer x={2} />
        <Logo width={110} />
      </Row>
      <Spacer y={2} />
      <UserProfile />
      <Spacer y={2} />
      <MenuItemLink
        key="analytics"
        itemName="analytics"
        to="/analytics"
        primaryText={t('buttons.analytics')}
        leftIcon={<DashboardRounded />}
        disabled={!isSwanActive}
      />
      <MenuItemLink
        key="contract"
        itemName="contract"
        to="/contract"
        primaryText={tRole('employee', true)}
        leftIcon={<People />}
        id="sidebar-menu-user-link"
      />
      <MenuItemLink
        key="transactions"
        itemName="transactions"
        to="/transactions"
        primaryText={t('resources.transactions.pluralName')}
        leftIcon={<CompareArrows />}
        disabled={!isSwanActive}
      />
      <MenuItemLink
        key="bank"
        itemName="bank"
        to="/bank"
        primaryText={t('buttons.bank')}
        leftIcon={<MonetizationOnRounded />}
        disabled={!isSwanActive}
      />
      {organization.borrowingEnabled && isSwanActive && (
        <MenuItemLink
          key="financing"
          itemName="financing"
          to="/financing/loans"
          primaryText={t('buttons.financing')}
          leftIcon={<EuroRounded />}
        />
      )}
      <CanAccess roles={NESSPAY_ONLY_ROLES}>
        <MenuItemLink
          key="activity"
          itemName="activity"
          to="/activity"
          primaryText={t('resources.activity.pluralName')}
          leftIcon={<ManageHistory />}
        />
      </CanAccess>
      <MenuItemLink
        key="settings"
        itemName="settings"
        to="/administration/profil"
        primaryText={t('buttons.settings')}
        leftIcon={<Settings />}
        disabled={!isSwanActive && !isAdmin}
      />
      {!hasCompletedOnboarding && (
        <MenuItemLink
          key="onboarding"
          itemName="onboarding"
          to="/onboarding"
          primaryText={t('buttons.onboarding')}
          leftIcon={<PermIdentityRounded />}
        />
      )}
      <CanAccess roles={['admin']}>
        <MenuItemLink
          key="admin"
          itemName="admin"
          to="/super-admin/create-organization"
          primaryText={t('buttons.admin')}
          leftIcon={<AdminPanelSettings />}
        />
      </CanAccess>
    </MenuSection>
  )
}

const StyledMenu = styled(Menu)(({ theme }) => ({
  boxSizing: 'border-box',
  backgroundColor: theme.palette.secondary.main,
  justifyContent: 'space-between',
  paddingLeft: 10,
  paddingRight: 10,
  margin: 0,
}))

export const CustomMenu = (props: any) => {
  return (
    <StyledMenu {...props}>
      <SidebarTopSection />
      <SidebarBottomSection />
    </StyledMenu>
  )
}
