import * as Joi from 'joi'

import { ImportedUser } from 'api/gen'

const { string, number, date } = Joi.types()

const objectSchema = Joi.object<ImportedUser>()
  .options({ abortEarly: false })
  .keys({
    firstName: string.required(),
    lastName: string.required(),
    email: string.required(),
    staffNumber: string.required(),
    iban: string.required(),
    netSalary: number.optional(),
    availableSalaryFraction: number.optional(),
    phone: string.allow('').optional().empty(''),
    isAdvanceServiceEnabled: string.optional(),
    contractEndDate: Joi.when(Joi.object({ contractEndDate: Joi.exist() }), {
      then: Joi.object({ contractEndDate: Joi.date().optional() }),
    }),
    contractStartDate: date.optional(),
    entityName: string.optional(),
  })

export const importUserSchema = Joi.array()
  .items(objectSchema)
  .unique('iban', { ignoreUndefined: true })
  .unique('email', { ignoreUndefined: true })
  .unique('staffNumber', { ignoreUndefined: true })
  .unique('phone', { ignoreUndefined: true })
  .preferences({ abortEarly: false })
