import { LoadingButton as Button } from '@mui/lab'
import { Box, Typography, TextField, SxProps } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { useApi } from 'api'
import { QueryKey } from 'api/queryKeys'
import { ReactComponent as Logo } from 'assets/logo_subtitle.svg'
import { useAuthContext } from 'libs/auth/provider'
import { OtcInput } from 'libs/components/OtcInput'
import { useNotifications } from 'libs/notifications'
import { useFormValidation } from 'libs/validation/form'
import { Spacer } from 'utils/spacing'

import { LOCALIZATIONS } from './localizations'

type LocaleType = 'fr' | 'en'

function getUserLocale(): LocaleType {
  const language = navigator.language
  const locale = language.split('-')[0]
  return locale === 'fr' || locale === 'en' ? locale : 'en'
}

export const SignInPage: React.FC = () => {
  const locale = getUserLocale()
  const localized = LOCALIZATIONS[locale]

  const api = useApi()
  const navigate = useNavigate()
  const notify = useNotifications()
  const { setAuth } = useAuthContext()

  const [code, setCode] = useState<string>('')
  const [email, setEmail] = useState<string>('')

  const { email: validateEmail } = useFormValidation()
  const [isUserValid, setUserValid] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    if (code.length === 6) {
      validateCodeMutation.mutate()
    }
  }, [code])

  const generateCodeMutation = useMutation(
    QueryKey.GenerateCode,
    async () => {
      await api.authControllerGenerateCode({ email })
    },
    {
      onSuccess: () => {
        setUserValid(true)
      },
      onError: () => {
        setUserValid(false)
      },
    }
  )

  const validateCodeMutation = useMutation(
    QueryKey.GenerateCode,
    async () => {
      const { token } = await api.authControllerValidateCode({ email, code })
      setAuth({ accessToken: token })
    },
    {
      onSuccess: () => {
        setAuth({ isAuthenticated: true })
        navigate('/analytics')
      },
      onError: () => {
        notify(localized.otc.otcInputHelperText, { variant: 'error' })
      },
    }
  )

  async function handleContinue() {
    if (isUserValid) {
      if (code.length !== 6) notify(localized.otc.otcIsRequired, { variant: 'error' })
      else await validateCodeMutation.mutateAsync()
    } else if (!Boolean(validateEmail(email))) {
      await generateCodeMutation.mutateAsync()
    }
  }

  async function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter') {
      await handleContinue()
    }
  }

  async function resendCode() {
    await generateCodeMutation.mutateAsync()
    notify(localized.otc.otcResent, { variant: 'success' })
  }

  return (
    <Box sx={containerSx}>
      <Logo />
      <Spacer y={2} />
      <Typography variant="h4" sx={{ ...typographySx, color: '#06113F' }}>
        {isUserValid ? localized.otc.otcTitle : localized.title}
      </Typography>
      <Spacer y={3} />
      {!isUserValid && (
        <TextField
          fullWidth
          value={email}
          variant="outlined"
          onKeyDown={handleKeyDown}
          label={localized.email.emailInputLabel}
          onChange={(event) => setEmail(event.target.value)}
          error={Boolean(validateEmail(email)) || isUserValid === false}
          helperText={isUserValid === false ? localized.email.emailInputHelperText : ''}
          sx={{ maxWidth: 400 }}
        />
      )}
      {isUserValid && (
        <>
          <Typography variant="subtitle1" sx={typographySx}>
            {localized.otc.otcMessage}
          </Typography>
          <Typography variant="subtitle1" sx={typographySx}>
            {email}
          </Typography>
          <Spacer y={2} />
          <OtcInput onChange={(code) => setCode(code)} />
        </>
      )}
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={handleContinue}
        loading={generateCodeMutation.isLoading || validateCodeMutation.isLoading}
        sx={continueButtonSx}>
        {localized.continue}
      </Button>
      <Spacer y={1} />
      {isUserValid && (
        <Button variant="text" color="primary" onClick={resendCode} sx={resendCodeButtonSx}>
          {localized.otc.otcResend}
        </Button>
      )}
    </Box>
  )
}

const containerSx: SxProps = {
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
}

const resendCodeButtonSx: SxProps = {
  fontSize: 16,
  fontWeight: 500,
  color: '#06113F',
  alignSelf: 'center',
  fontFamily: 'outfit',
  textTransform: 'none',
  ':hover': { textDecoration: 'underline', backgroundColor: 'none' },
}

const continueButtonSx: SxProps = {
  mt: 2,
  fontSize: 16,
  maxWidth: 400,
  fontWeight: 400,
  fontFamily: 'outfit',
  textTransform: 'none',
  backgroundColor: '#06113F',
  ':hover': { backgroundColor: '#A9AFC5' },
}

const typographySx: SxProps = {
  color: '#A9AFC5',
  fontFamily: 'outfit',
}
