import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Button,
  SxProps,
  useTheme,
} from '@mui/material'
import React from 'react'
import { useTranslate } from 'react-admin'

import { useModal } from 'libs/modal'
import { usePersistedUser } from 'user/context'
import { Spacer } from 'utils/spacing'

export const ConfirmLogoutDialog = () => {
  const t = useTranslate()
  const theme = useTheme()
  const { logout } = usePersistedUser()
  const { closeModal, isModalOpen } = useModal('logout')

  function handleLogout() {
    logout()
    closeModal()
  }

  return (
    <Dialog open={isModalOpen} onClose={closeModal} sx={dialogSx}>
      <DialogTitle>
        <Typography variant="h1" sx={{ textAlign: 'center' }}>
          {t('buttons.logout.title')}
        </Typography>
      </DialogTitle>
      <Spacer y={1} />
      <DialogContent>
        <Button
          variant="contained"
          color="error"
          sx={{ ...buttonSx, color: theme.palette.text.secondary }}
          onClick={handleLogout}>
          {t('buttons.logout.confirm')}
        </Button>
        <Spacer y={3} />
        <Button variant="outlined" color="secondary" sx={buttonSx} onClick={closeModal}>
          {t('buttons.logout.cancel')}
        </Button>
      </DialogContent>
    </Dialog>
  )
}

const dialogSx: SxProps = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '350px',
      height: '100%',
      maxHeight: '230px',
    },
  },
}

const buttonSx: SxProps = {
  width: '300px',
}
