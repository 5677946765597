import React, { createContext, useContext, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { storage } from 'user/storage'
import { doNothing } from 'utils/doNothing'

import { Auth, AuthContextType } from './types'
import { useRefreshToken } from './useRefreshToken'

function getInitialAuth(): Auth {
  const swanSecurityState = uuid()
  const storedAuth = storage.getObject<Auth>('@Auth')
  if (storedAuth) return storedAuth
  storage.save('@Auth', { swanSecurityState })
  return { swanSecurityState }
}

const INITIAL_AUTH = getInitialAuth()

export const INITIAL_AUTH_CONTEXT: AuthContextType = {
  auth: INITIAL_AUTH,
  setAuth: doNothing,
}

const AuthContext = createContext<AuthContextType>(INITIAL_AUTH_CONTEXT)

export const AuthProvider: React.FC = (props) => {
  const { token: accessToken } = useRefreshToken()
  const [auth, setAuth] = useState<Auth>(INITIAL_AUTH)

  const updateAuth = (newValues: Partial<Auth>) => {
    setAuth((prevAuth) => ({
      ...prevAuth,
      ...newValues,
    }))
  }

  const contextValue = useMemo(() => {
    const newAuth = { ...auth }
    if (accessToken) {
      newAuth.accessToken = accessToken
      newAuth.isAuthenticated = true
    }
    storage.save('@Auth', newAuth)
    return {
      auth: newAuth,
      setAuth: updateAuth,
    }
  }, [auth, accessToken])

  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
}

export function useAuthContext() {
  return useContext(AuthContext)
}
