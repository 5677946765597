import { Card, CardContent } from '@mui/material'
import React, { useEffect } from 'react'
import { Title } from 'react-admin'
import { useNavigate } from 'react-router-dom'

import { useApi } from 'api'
import { config } from 'config'
import { useAuthContext } from 'libs/auth/provider'
import { useQueryParams } from 'utils/navigation'

export const SwanAuthRedirectPage = () => {
  const api = useApi()
  const queryParams = useQueryParams()
  const navigate = useNavigate()
  const { setAuth } = useAuthContext()
  const code = queryParams.get('code')
  const state = queryParams.get('state')

  useEffect(() => {
    if (!code || !state) return
    api
      .swanControllerGetUserTokenFromCode({
        code,
        redirectUrl: config.SWAN_AUTH_REDIRECT_URL,
      })
      .then(({ access_token, refresh_token }) => {
        setAuth({ swanAccessToken: access_token, swanRefreshToken: refresh_token })
        navigate('/analytics')
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  return (
    <Card>
      <Title title="Page d'authentification de Swan" />
      <CardContent>{"Page d'authentification de Swan..."}</CardContent>
    </Card>
  )
}
