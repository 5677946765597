import {
  InfoRounded,
  WarningAmberRounded,
  CheckCircleRounded,
  CheckRounded,
  EditRounded,
  DeleteRounded,
  RestartAltRounded,
} from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  styled,
  useTheme,
  SxProps,
  Button,
  SvgIcon,
  Typography,
  Tooltip,
  Box,
  IconButton,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import MaterialReactTable, {
  MaterialReactTableProps,
  MRT_Cell,
  MRT_ColumnDef,
  MRT_Row,
} from 'material-react-table'
import React, { useContext, useMemo, useState, useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { useMutation } from 'react-query'

import { useApi } from 'api'
import { ImportedUser } from 'api/gen'
import { QueryKey } from 'api/queryKeys'
import { HorizontalStepper } from 'libs/import/components/Stepper'
import { ImportUsersContext, ValidationSteps } from 'libs/import/context'
import { convertFrenchDateToISOString, isValidFrenchDate } from 'libs/validation/date'
import { useFormValidation } from 'libs/validation/form'
import { Column, Spacer, Row } from 'utils/spacing'
import { useEffectOnce } from 'utils/useEffectOnce'

import { USER_FIELDS, NesspayFields, AdvanceServiceValues } from '../constants'
import { importUserSchema } from '../importValidator'

interface Errors {
  [cellId: string]: string
}

export function removeUsersToDeleteFromFile(fileContent: any, usersToDelete: ImportedUser[]) {
  const newFileContent = [...fileContent]
  for (const user of usersToDelete) {
    const index = newFileContent.findIndex((field: any) => field.staffNumber === user.staffNumber)
    if (index > -1) {
      newFileContent.splice(index, 1)
    }
  }
  return newFileContent
}

export const ImportEditTable = () => {
  const api = useApi()
  const t = useTranslate()
  const theme = useTheme()

  const {
    currentStep,
    validationStep,
    fileContent,
    usersToDelete,
    setCurrentStep,
    setFileContent,
    setValidationStep,
    setUsersToDelete,
  } = useContext(ImportUsersContext)

  const [validationErrors, setValidationErrors] = useState<Errors>({})
  const [uniquenessErrors, setUniquenessErrors] = useState<Errors>({})
  const [dbUniquenessErrors, setDbUniquenessErrors] = useState<Errors>({})

  const [shouldDisplayInvalidContent, setDisplayInvalidContent] = useState<boolean>(true)

  const validation = useFormValidation()

  useEffectOnce(() => {
    validateFileContent()
    const filterButton = document.getElementById('filterButtonID')
    if (filterButton) filterButton.click()
  })

  async function validateFileContent(newFileContent: any = fileContent) {
    validateCellValues(newFileContent)
    validateCellUniqueness(newFileContent)
    await validateUniquenessMutation.mutateAsync(newFileContent)
  }

  const validateUniquenessMutation = useMutation(QueryKey.ImportValidate, validateUniquenessInDB, {
    retryDelay: 2000,
  })

  async function validateUniquenessInDB(fileContent: any): Promise<Errors> {
    const joiErrors: Errors = {}
    const newFileContent = removeUsersToDeleteFromFile(fileContent, usersToDelete)
    newFileContent.forEach((row: any) => {
      for (const key in row) {
        if (row[key] === null || row[key] === undefined || row[key] === '') delete row[key]
      }
    })
    const { errors } = await api.importControllerImportValidation({ importedUsers: newFileContent })
    if (errors.length) {
      for (const error of errors) {
        for (let index = 0; fileContent[index]; index++) {
          if (fileContent[index][error.field] === error.value) {
            const cellId = index + '_' + error.field
            joiErrors[cellId] = t('error.uniqueness.unspecific')
          }
        }
      }
    }
    setDbUniquenessErrors(joiErrors)
    return joiErrors
  }

  function validateCellValues(fileContent: any) {
    const validationErrors: Errors = {}
    fileContent.forEach((row: any[], index: number) => {
      for (const key in row) {
        const value = row[key]
        const validationError =
          key === NesspayFields.EMAIL
            ? validation.combine(value, validation.required, validation.email)
            : key === NesspayFields.PHONE
            ? validation.frenchPhone(value)
            : key === NesspayFields.IBAN
            ? validation.combine(value, validation.required, validation.iban)
            : key === NesspayFields.NET_SALARY
            ? validation.netSalary(value)
            : key === NesspayFields.AVAILABLE_SALARY_FRACTION
            ? validation.salaryFraction(value)
            : key === NesspayFields.CONTRACT_START_DATE || key === NesspayFields.CONTRACT_END_DATE
            ? validation.frenchDate(value)
            : key === NesspayFields.FIRST_NAME ||
              key === NesspayFields.LAST_NAME ||
              key === NesspayFields.STAFF_NUMBER
            ? validation.required(value)
            : validation.maxLength(value)
        if (validationError) {
          validationErrors[`${index}_${key}`] = validationError
        }
      }
    })
    setValidationErrors(validationErrors)
  }

  function validateCellUniqueness(fileContent: any) {
    const joiErrors: Errors = {}
    const { error: uniquenessError } = importUserSchema.validate(fileContent)
    if (uniquenessError) {
      uniquenessError.details.forEach((error) => {
        if (error.type === 'array.unique') {
          // avoid uniqueness error for empty fields
          if (!error.context?.dupeValue[error.context?.path]) return
          const cellId = error.context?.key + '_' + error.context?.path
          joiErrors[cellId] = t('error.uniqueness.unspecific')
        }
        if (error.type === 'string.base') {
          const cellId = error.path[0] + '_' + error.path[1]
          joiErrors[cellId] = t('error.stringRequired')
        }
      })
    }
    setUniquenessErrors(joiErrors)
  }

  const handleSaveRow: MaterialReactTableProps<any>['onEditingRowSave'] = async ({
    exitEditingMode,
    row,
    values,
  }: any) => {
    if (
      values.hasOwnProperty(NesspayFields.CONTRACT_START_DATE) &&
      isValidFrenchDate(values[NesspayFields.CONTRACT_START_DATE])
    ) {
      values[NesspayFields.CONTRACT_START_DATE] = convertFrenchDateToISOString(
        values[NesspayFields.CONTRACT_START_DATE]
      )
    }
    if (
      values.hasOwnProperty(NesspayFields.CONTRACT_END_DATE) &&
      isValidFrenchDate(values[NesspayFields.CONTRACT_END_DATE])
    ) {
      values[NesspayFields.CONTRACT_END_DATE] = convertFrenchDateToISOString(
        values[NesspayFields.CONTRACT_END_DATE]
      )
    }
    const newFileContent = [...fileContent]
    newFileContent[row.index] = values
    setFileContent(newFileContent)
    await validateFileContent(newFileContent)
    exitEditingMode()
  }

  const getTextFieldProps = useCallback(
    (cell: MRT_Cell): MRT_ColumnDef<any>['muiTableBodyCellEditTextFieldProps'] => {
      if (cell.column.id === NesspayFields.IS_ADVANCE_SERVICE_ENABLED) {
        return {
          select: true,
          children: Object.values(AdvanceServiceValues).map((value) => (
            <MenuItem key={value} value={value}>
              {t(`resources.contract.accessValue.${value}`)}
            </MenuItem>
          )),
        }
      }
      return {
        autoComplete: 'off',
        error:
          !!validationErrors[cell.id] ||
          !!uniquenessErrors[cell.id] ||
          !!dbUniquenessErrors[cell.id],
        helperText:
          validationErrors[cell.id] || uniquenessErrors[cell.id] || dbUniquenessErrors[cell.id],
        type:
          cell.column.id === NesspayFields.NET_SALARY ||
          cell.column.id === NesspayFields.AVAILABLE_SALARY_FRACTION
            ? 'number'
            : 'text',
        onChange: (event) => {
          const value = event.target.value
          const error =
            cell.column.id === NesspayFields.EMAIL
              ? validation.combine(value, validation.required, validation.email)
              : cell.column.id === NesspayFields.PHONE
              ? validation.frenchPhone(value)
              : cell.column.id === NesspayFields.IBAN
              ? validation.combine(value, validation.required, validation.iban)
              : cell.column.id === NesspayFields.NET_SALARY
              ? validation.netSalary(parseInt(value))
              : cell.column.id === NesspayFields.AVAILABLE_SALARY_FRACTION
              ? validation.salaryFraction(parseInt(value))
              : cell.column.id === NesspayFields.CONTRACT_START_DATE ||
                NesspayFields.CONTRACT_END_DATE
              ? validation.frenchDate(value)
              : cell.column.id === NesspayFields.FIRST_NAME ||
                cell.column.id === NesspayFields.LAST_NAME ||
                cell.column.id === NesspayFields.STAFF_NUMBER
              ? validation.required(value)
              : validation.maxLength(value)
          if (error) {
            setValidationErrors({
              ...validationErrors,
              [cell.id]: error,
            })
          } else {
            delete validationErrors[cell.id]
            setValidationErrors({
              ...validationErrors,
            })
          }
        },
      }
    },
    [t, validationErrors, uniquenessErrors, dbUniquenessErrors, validation]
  )

  function getHelperText() {
    const errors =
      Object.keys(validationErrors).length +
      Object.keys(uniquenessErrors).length +
      Object.keys(dbUniquenessErrors).length
    if (errors === 0) return t('resources.contract.helperTexts.importTab.noCellsMissingSubtitle')
    return errors + ' ' + t('resources.contract.helperTexts.importTab.missingCellsSubtitle', errors)
  }

  function handleDeleteUser(row: MRT_Row<any>) {
    const users = [...usersToDelete]
    if (users.includes(fileContent[row.index])) {
      const index = users.indexOf(fileContent[row.index])
      users.splice(index, 1)
      setUsersToDelete(users)
      return
    }
    users.push(fileContent[row.index])
    setUsersToDelete(users)
  }

  const filterRows = useCallback(
    (row: MRT_Row<any>) => {
      const invalidRows = Object.keys(validationErrors).map((error) =>
        error.match(/\d+/g)?.toString()
      )
      const nonUniqueRowsInFile = Object.keys(uniquenessErrors).map((error) =>
        error.match(/\d+/g)?.toString()
      )
      const nonUniqueRowsInDB = Object.keys(dbUniquenessErrors).map((error) =>
        error.match(/\d+/g)?.toString()
      )
      if (shouldDisplayInvalidContent) {
        return (
          invalidRows.includes(row.id) ||
          nonUniqueRowsInFile.includes(row.id) ||
          nonUniqueRowsInDB.includes(row.id)
        )
      } else return undefined
    },
    [shouldDisplayInvalidContent, validationErrors, uniquenessErrors, dbUniquenessErrors]
  )

  const toggleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filterButton = document.getElementById('filterButtonID')
    if (filterButton) filterButton.click()
    setDisplayInvalidContent(event.target.checked)
  }

  const ErrorMessage = () => {
    const filterButton = document.getElementById('filterButtonID')
    let message = t('resources.contract.helperTexts.importTab.noCellsMissingSubtitle')
    if (Object.keys(dbUniquenessErrors).length) {
      message = t(
        'resources.contract.helperTexts.importTab.errorMessage.dbError',
        Object.keys(dbUniquenessErrors).length
      )
    }
    if (filterButton) filterButton.click()
    if (Object.keys(validationErrors).length || Object.keys(uniquenessErrors).length) {
      message = t(
        'resources.contract.helperTexts.importTab.errorMessage.fileError',
        Object.keys(validationErrors).length + Object.keys(uniquenessErrors).length
      )
    }
    return <Typography sx={{ alignSelf: 'center' }}>{message}</Typography>
  }

  const memoizedColumns: any[] = useMemo(() => {
    const columnsArray: any[] = []
    const columns = Object.keys(fileContent[0])
    columns.forEach((nesspayField) => {
      const translationName = USER_FIELDS.includes(nesspayField || '')
        ? 'user.' + nesspayField
        : nesspayField
      columnsArray.push({
        accessorKey: nesspayField,
        size: 50,
        header: t(`resources.contract.fields.${translationName}`),
        Filter: ({ header }: any) => (
          <Button
            id="filterButtonID"
            sx={{ display: 'none' }}
            onClick={() => header.column.setFilterValue(shouldDisplayInvalidContent)}></Button>
        ),
        filterFn: (row: MRT_Row<any>) => filterRows(row),
        Cell: ({ cell }: any) => (
          <Tooltip
            title={
              validationErrors[cell.id] ||
              uniquenessErrors[cell.id] ||
              dbUniquenessErrors[cell.id] ||
              ''
            }>
            <Box
              sx={{
                maxWidth: '60px',
                minWidth:
                  nesspayField === NesspayFields.FIRST_NAME ||
                  nesspayField === NesspayFields.LAST_NAME
                    ? '43px'
                    : '30px',
              }}>
              <Typography variant="h3" noWrap sx={{ overflow: 'hidden' }}>
                {cell.column.id !== NesspayFields.IS_ADVANCE_SERVICE_ENABLED
                  ? cell.getValue()
                  : cell.getValue()
                  ? t(`resources.contract.accessValue.${cell.getValue()}`)
                  : t(`resources.contract.accessValue.${AdvanceServiceValues.NO}`)}
              </Typography>
            </Box>
          </Tooltip>
        ),
        muiTableBodyCellEditTextFieldProps: ({ cell }: any) => ({
          ...getTextFieldProps(cell),
        }),
        muiTableBodyCellProps: ({ cell }: any) => ({
          sx: {
            border: '1px solid',
            borderColor: theme.colors.GREY,
            color:
              validationErrors[cell.id] || uniquenessErrors[cell.id] || dbUniquenessErrors[cell.id]
                ? theme.colors.GREY_LIGHT
                : theme.palette.text.primary,
            backgroundColor:
              validationErrors[cell.id] || uniquenessErrors[cell.id] || dbUniquenessErrors[cell.id]
                ? theme.colors.RED
                : theme.palette.text.secondary,
          },
        }),
      })
    })
    return columnsArray
  }, [
    t,
    theme,
    fileContent,
    getTextFieldProps,
    validationErrors,
    uniquenessErrors,
    dbUniquenessErrors,
    shouldDisplayInvalidContent,
    filterRows,
  ])

  if (validationStep === ValidationSteps.HAS_ERRORS) {
    const isValid =
      !Object.keys(validationErrors).length &&
      !Object.keys(uniquenessErrors).length &&
      !Object.keys(dbUniquenessErrors).length
    const color = isValid ? theme.colors.GREEN : theme.colors.ORANGE
    return (
      <Column sx={columnSx}>
        <Spacer y={2} />
        <HorizontalStepper activeStep={currentStep} />
        <Spacer y={5} />
        <StyledSquare>
          <StyledCircle sx={{ borderColor: color }}>
            <SvgIcon sx={{ width: '40px', height: '50px' }}>
              {isValid ? (
                <CheckRounded sx={{ color: theme.colors.GREEN }} />
              ) : (
                <WarningAmberRounded sx={{ color: theme.colors.ORANGE }} />
              )}
            </SvgIcon>
          </StyledCircle>
          <Spacer y={2} />
          <StyledTypography variant="h2" sx={{ fontWeight: 500 }}>
            {isValid
              ? t('resources.contract.helperTexts.importTab.noCellsMissingTitle')
              : t('resources.contract.helperTexts.importTab.missingCellsTitle')}
          </StyledTypography>
          <Row>
            <SvgIcon sx={{ color: color }}>
              {isValid ? <CheckCircleRounded /> : <InfoRounded />}
            </SvgIcon>
            <Spacer x={1} />
            <Typography sx={{ color: color }}>{getHelperText()}</Typography>
          </Row>
          <Spacer y={5} />
          <Button
            variant="contained"
            color="primary"
            onClick={() => setValidationStep(ValidationSteps.TABLE_EDIT)}
            disabled={isValid}
            sx={buttonSx}>
            {t('buttons.import.updateFileContent')}
          </Button>
        </StyledSquare>
        <Spacer y={5} />
        <Row>
          <Button
            variant="outlined"
            color="secondary"
            sx={buttonSx}
            onClick={() => setValidationStep(ValidationSteps.COLUMNS_MATCHING)}>
            {t('buttons.import.prevStep')}
          </Button>
          <Spacer x={4} />
          <LoadingButton
            variant="contained"
            disabled={!isValid}
            sx={buttonSx}
            onClick={() => setCurrentStep(2)}>
            {t('buttons.import.nextStep')}
          </LoadingButton>
        </Row>
      </Column>
    )
  }

  return (
    <Column sx={columnSx}>
      <Spacer y={2} />
      <HorizontalStepper activeStep={currentStep} />
      <Spacer y={5} />
      <StyledSquare
        sx={{ width: validationStep === ValidationSteps.TABLE_EDIT ? '1100px' : '650px' }}>
        <FormGroup sx={{ alignSelf: 'start', width: '100%' }}>
          <ErrorMessage />
          <Spacer y={2} />
          <FormControlLabel
            sx={{ color: theme.colors.GREY }}
            control={
              <Checkbox
                sx={{ color: theme.palette.secondary.main }}
                onChange={toggleFilter}
                checked={shouldDisplayInvalidContent}
              />
            }
            label={t('resources.contract.helperTexts.importTab.displayAllRows')}
          />
        </FormGroup>
        <Spacer y={3} />
        <MaterialReactTable
          columns={memoizedColumns}
          data={fileContent}
          enableEditing
          enableRowActions
          enableStickyHeader
          enableTopToolbar={false}
          enableBottomToolbar={false}
          enableColumnActions={false}
          enablePagination={false}
          initialState={{ showColumnFilters: true }}
          muiTableContainerProps={{ sx: { maxHeight: '350px' } }}
          muiTableBodyCellEditTextFieldProps={{ variant: 'standard', sx: { width: '100%' } }}
          muiTablePaperProps={{ elevation: 0, sx: { maxWidth: '1300px', height: '100%' } }}
          muiTableBodyCellProps={{
            sx: { border: '1px solid', borderColor: theme.colors.GREY },
          }}
          muiTableHeadCellProps={{
            sx: {
              border: '1px solid',
              borderColor: theme.colors.GREY,
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.text.secondary,
              fontFamily: 'Outfit',
              fontWeight: 500,
            },
          }}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex' }}>
              <IconButton
                sx={{
                  color: theme.palette.secondary.main,
                }}
                onClick={() => table.setEditingRow(row)}>
                <EditRounded />
              </IconButton>
              <IconButton
                sx={{
                  color: !usersToDelete.includes(fileContent[row.index])
                    ? theme.colors.RED
                    : 'green',
                }}
                onClick={() => handleDeleteUser(row)}>
                {!usersToDelete.includes(fileContent[row.index]) ? (
                  <DeleteRounded />
                ) : (
                  <RestartAltRounded />
                )}
              </IconButton>
            </Box>
          )}
          onEditingRowSave={handleSaveRow}
        />
      </StyledSquare>
      <Spacer y={5} />
      <Row>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setValidationStep(ValidationSteps.COLUMNS_MATCHING)}
          sx={buttonSx}>
          {t('buttons.import.prevStep')}
        </Button>
        <Spacer x={3} />
        <Button
          variant="contained"
          color="primary"
          sx={buttonSx}
          onClick={() => setValidationStep(ValidationSteps.HAS_ERRORS)}>
          {t('buttons.import.nextStep')}
        </Button>
      </Row>
    </Column>
  )
}

const buttonSx: SxProps = {
  width: '180px',
  height: '45px',
  fontWeight: 400,
  padding: 0,
}
const columnSx: SxProps = {
  display: 'flex',
  width: '100%',
  height: '90%',
  alignItems: 'center',
}

const StyledSquare = styled(Column)(({ theme }) => ({
  display: 'flex',
  height: 'auto',
  alignItems: 'center',
  justifyContent: 'center',
  border: '2px solid',
  borderRadius: '10px',
  borderColor: theme.palette.secondary.main,
  padding: '45px 120px 45px 120px',
}))

const StyledCircle = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '80px',
  height: '80px',
  border: '1px solid',
  borderRadius: '50px',
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  height: '50px',
  width: '400px',
  textAlign: 'center',
  fontSize: '16px',
  color: theme.palette.secondary.main,
}))
