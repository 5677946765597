import { useState } from 'react'

import { useApi } from 'api'
import { usePersistedUser } from 'user/context'
import { storage } from 'user/storage'
import { useInterval } from 'utils/useInterval'

import { Auth } from './types'

const TEN_MINUTES = 10 * 60 * 1000

export const useRefreshToken = () => {
  const api = useApi()
  const { logout } = usePersistedUser()
  const [token, setToken] = useState<string | undefined>(undefined)

  useInterval(
    () => {
      const storedAuth = storage.getObject<Auth>('@Auth')
      if (!storedAuth) return
      const { accessToken: storedToken } = storedAuth
      api
        .authControllerRefreshToken({
          token: storedToken,
        })
        .then(({ token }) => {
          setToken(token)
        })
        .catch(() => {
          logout()
          console.error
        })
    },
    true,
    TEN_MINUTES
  )

  return { token }
}
