import { LoadingButton } from '@mui/lab'
import { TextField, FormControlLabel, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useTranslate } from 'react-admin'
import { useMutation } from 'react-query'

import { useApi } from 'api'
import { OrganizationRequestDto } from 'api/gen'
import { useCanAccess } from 'libs/auth'
import { HorizontalDivider } from 'libs/components/Divider'
import { StyledSwitch } from 'libs/components/StyledSwitch'
import { useNotifications } from 'libs/notifications'
import { usePersistedUser } from 'user/context'
import { Row, Column, Spacer } from 'utils/spacing'

type ErrorsType = {
  name: boolean
  swanAccountId: boolean
  klaroPrivateKey: boolean
  alertBalance: boolean
  minimumBalance: boolean
  defaultNetSalary: boolean
  defaultAvailableSalaryFraction: boolean
}

export function CompanyTabAdminForm() {
  const api = useApi()
  const t = useTranslate()
  const { canAccess } = useCanAccess()
  const notification = useNotifications()
  const isAdmin = canAccess({ roles: ['admin'] })
  const { organization, setOrganization } = usePersistedUser()

  const [errors, setErrors] = useState<ErrorsType>({
    name: false,
    swanAccountId: false,
    klaroPrivateKey: false,
    alertBalance: false,
    minimumBalance: false,
    defaultNetSalary: false,
    defaultAvailableSalaryFraction: false,
  })

  const [name, setName] = useState<string>('')
  const [swanAccountId, setSwanAccountId] = useState<string | undefined>(undefined)
  const [klaroPrivateKey, setKlaroPrivateKey] = useState<string>('')

  const [alertBalance, setAlertBalance] = useState<number>(5000)
  const [minimumBalance, setMinimumBalance] = useState<number>(200)
  const [defaultNetSalary, setDefaultNetSalary] = useState<number>(0)

  const [sendInvitationEmails, setSendInvitationEmails] = useState<boolean>(true)
  const [isToutesMesAidesEnabled, setIsToutesMesAidesEnabled] = useState<boolean>(false)
  const [borrowingEnabled, setBorrowingEnabled] = useState<boolean>(false)

  useEffect(() => {
    setName(organization.name)
    setAlertBalance(organization.alertBalance)
    setSwanAccountId(organization.swanAccountId)
    setMinimumBalance(organization.minimumBalance)
    setKlaroPrivateKey(organization.klaroPrivateKey)
    setBorrowingEnabled(organization.borrowingEnabled)
    setDefaultNetSalary(organization.defaultNetSalary)
    setSendInvitationEmails(organization.sendInvitationEmails)
    setIsToutesMesAidesEnabled(organization.isToutesMesAidesEnabled)
  }, [organization])

  const adminExportMutation = useMutation(async () => {
    await api.advancesControllerGetAdminAdvancesExport()
  })

  const syncWithIntercomMutation = useMutation(
    async () => {
      await api.contractControllerSyncUsersWithIntercomUsers()
    },
    {
      onSuccess: () => {
        notification(t('notifications.intercomSync.success'), { variant: 'success' })
      },
      onError: () => {
        notification(t('notifications.intercomSync.error'), { variant: 'error' })
      },
    }
  )

  const updateOrganizationMutation = useMutation(
    async () => {
      return await api.organizationControllerUpdateOne({
        name,
        swanAccountId,
        klaroPrivateKey,
        alertBalance,
        defaultNetSalary,
        sendInvitationEmails,
        isToutesMesAidesEnabled,
        borrowingEnabled,
        minimumBalance,
      } as OrganizationRequestDto)
    },
    {
      onSuccess: () => {
        notification(t('notifications.organizationEdit.success'), { variant: 'success' })
        setOrganization({
          name,
          swanAccountId,
          klaroPrivateKey,
          alertBalance,
          defaultNetSalary,
          sendInvitationEmails,
          isToutesMesAidesEnabled,
          borrowingEnabled,
          minimumBalance,
        })
      },
      onError: (error) => {
        console.log(error)
        notification(t('notifications.organizationEdit.error'), { variant: 'error' })
      },
    }
  )

  function handleTextFieldChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof typeof errors
  ) {
    const value = event?.target?.value
    if (!value || !value.length) setErrors((prevErrors) => ({ ...prevErrors, [field]: true }))
    else setErrors((prevErrors) => ({ ...prevErrors, [field]: false }))
    if (field === 'name') setName(value)
    if (field === 'swanAccountId') setSwanAccountId(value)
    if (field === 'klaroPrivateKey') setKlaroPrivateKey(value)
  }

  function handleNumberFieldChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof typeof errors
  ) {
    const value = parseInt(event?.target?.value)
    if (field === 'alertBalance') {
      if (value < 0) setErrors((prevErrors) => ({ ...prevErrors, [field]: true }))
      else setErrors((prevErrors) => ({ ...prevErrors, [field]: false }))
      setAlertBalance(value)
    }
    if (field === 'minimumBalance') {
      if (value < 0) setErrors((prevErrors) => ({ ...prevErrors, [field]: true }))
      else setErrors((prevErrors) => ({ ...prevErrors, [field]: false }))
      setMinimumBalance(value)
    }
    if (field === 'defaultNetSalary') {
      if (value < 0 || value > 2000) setErrors((prevErrors) => ({ ...prevErrors, [field]: true }))
      else setErrors((prevErrors) => ({ ...prevErrors, [field]: false }))
      setDefaultNetSalary(value)
    }
    if (field === 'defaultAvailableSalaryFraction') {
      if (value < 0 || value > 100) setErrors((prevErrors) => ({ ...prevErrors, [field]: true }))
      else setErrors((prevErrors) => ({ ...prevErrors, [field]: false }))
    }
  }

  const disableSubmitButton = () => {
    if (!organization || Object.values(errors).includes(true)) {
      return true
    }
    if (
      organization.name === name &&
      organization.swanAccountId === swanAccountId &&
      organization.klaroPrivateKey === klaroPrivateKey &&
      organization.alertBalance === alertBalance &&
      organization.minimumBalance === minimumBalance &&
      organization.defaultNetSalary === defaultNetSalary &&
      organization.sendInvitationEmails === sendInvitationEmails &&
      organization.isToutesMesAidesEnabled === isToutesMesAidesEnabled &&
      organization.borrowingEnabled === borrowingEnabled
    ) {
      return true
    }
    return false
  }

  return (
    <Column sx={{ width: '450px' }}>
      <Row>
        <TextField
          label={t('resources.organization.fields.name')}
          value={name}
          onChange={(event) => handleTextFieldChange(event, 'name')}
          error={errors.name}
          disabled={!isAdmin}
          sx={{ width: '50%' }}
        />
        <Spacer x={2} />
        <TextField
          label={t('resources.organization.fields.swanAccountId')}
          value={swanAccountId}
          onChange={(event) => handleTextFieldChange(event, 'swanAccountId')}
          error={errors.swanAccountId}
          disabled={!isAdmin}
          sx={{ width: '50%' }}
        />
      </Row>
      <Spacer y={2} />
      <Row>
        <TextField
          label={t('resources.organization.fields.klaroPrivateKey')}
          value={klaroPrivateKey}
          onChange={(event) => handleTextFieldChange(event, 'klaroPrivateKey')}
          error={errors.klaroPrivateKey}
          type="password"
          disabled={!isAdmin}
          sx={{ width: '50%' }}
        />
        <Spacer x={2} />
        <TextField
          label={t('resources.organization.fields.alertBalance')}
          type="number"
          value={alertBalance}
          onChange={(event) => handleNumberFieldChange(event, 'alertBalance')}
          error={errors.alertBalance}
          disabled={!isAdmin}
          sx={{ width: '50%' }}
        />
      </Row>
      <Spacer y={2} />
      <Row>
        <TextField
          label={t('resources.organization.fields.defaultNetSalary')}
          type="number"
          value={defaultNetSalary}
          onChange={(event) => handleNumberFieldChange(event, 'defaultNetSalary')}
          error={errors.defaultNetSalary}
          disabled={!isAdmin}
          sx={{ width: '50%' }}
        />
        <Spacer x={2} />
      </Row>
      <Spacer y={2} />
      <Row>
        <TextField
          label={t('resources.organization.fields.minimumBalance')}
          type="number"
          value={minimumBalance}
          onChange={(event) => handleNumberFieldChange(event, 'minimumBalance')}
          error={errors.minimumBalance}
          disabled={!isAdmin}
          sx={{ width: '50%' }}
        />
      </Row>
      <Spacer y={3} />
      <FormControlLabel
        sx={{ marginLeft: 0 }}
        control={
          <StyledSwitch
            color="primary"
            disabled={!isAdmin}
            checked={sendInvitationEmails}
            onChange={() => setSendInvitationEmails((prevValue) => !prevValue)}
          />
        }
        label={
          <Typography variant="body1" marginLeft={1}>
            {t('resources.organization.fields.sendInvitationEmails')}
          </Typography>
        }
      />
      <Spacer y={1} />
      <FormControlLabel
        sx={{ marginLeft: 0 }}
        control={
          <StyledSwitch
            color="primary"
            disabled={!isAdmin}
            checked={isToutesMesAidesEnabled}
            onChange={() => setIsToutesMesAidesEnabled((prevValue) => !prevValue)}
          />
        }
        label={
          <Typography variant="body1" marginLeft={1}>
            {t('resources.organization.fields.isToutesMesAidesEnabled')}
          </Typography>
        }
      />
      <Spacer y={1} />
      <FormControlLabel
        sx={{ marginLeft: 0 }}
        control={
          <StyledSwitch
            color="primary"
            disabled={!isAdmin}
            checked={borrowingEnabled}
            onChange={() => setBorrowingEnabled((prevValue) => !prevValue)}
          />
        }
        label={
          <Typography variant="body1" marginLeft={1}>
            {t('resources.organization.fields.borrowingEnabled')}
          </Typography>
        }
      />
      <Spacer y={5} />
      <LoadingButton
        variant="contained"
        loading={updateOrganizationMutation.isLoading}
        onClick={() => updateOrganizationMutation.mutate()}
        disabled={disableSubmitButton()}>
        {t('buttons.save')}
      </LoadingButton>
      <Spacer y={3} />
      <HorizontalDivider />
      <Spacer y={3} />
      <Row>
        <LoadingButton
          variant="contained"
          loading={syncWithIntercomMutation.isLoading}
          onClick={() => syncWithIntercomMutation.mutate()}
          sx={{ width: '50%' }}>
          {t('buttons.intercomSync')}
        </LoadingButton>
        <Spacer x={3} />
        <LoadingButton
          variant="contained"
          loading={adminExportMutation.isLoading}
          onClick={() => adminExportMutation.mutate()}
          sx={{ width: '50%' }}>
          {t('buttons.export.name')}
        </LoadingButton>
      </Row>
    </Column>
  )
}
