import { AddCircleOutlineRounded, CloseRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Typography, SxProps, useTheme, styled, Tooltip, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useQuery, useMutation } from 'react-query'

import { useApi } from 'api'
import { OrganizationApiAccessToken } from 'api/gen'
import { QueryKey } from 'api/queryKeys'
import { CanAccess } from 'libs/auth'
import { ConfirmDialog } from 'libs/components/ConfirmDialog'
import { useModal } from 'libs/modal'
import { useNotifications } from 'libs/notifications'
import { ROLES_WITH_WRITE_PERMISSION } from 'resources/types'
import { Column, Row, Spacer } from 'utils/spacing'

import { ApiTokensTable } from './ApiTokensTable'
import { DisplayApiTokenDialog } from './DisplayApiTokenDialog'

export function ApiTabView() {
  const api = useApi()
  const t = useTranslate()
  const theme = useTheme()
  const notify = useNotifications()

  const [token, setToken] = useState<string>('')
  const [tokens, setTokens] = useState<OrganizationApiAccessToken[]>([])

  const { openModal } = useModal('displayApiToken')
  const { openModal: openConfirm } = useModal('confirm')

  const { isFetching: isFetchingTokens, refetch: refetchTokens } = useQuery(
    QueryKey.GetApiTokens,
    async () => {
      try {
        const { accessTokens } = await api.organizationControllerGetTokens()
        setTokens(accessTokens)
      } catch (error) {
        throw error
      }
    }
  )

  const createTokenMutation = useMutation(
    QueryKey.CreateApiToken,
    async () => {
      const { secret: token } = await api.organizationControllerCreateToken()
      setToken(token)
      openModal()
    },
    {
      onSuccess: async () => {
        notify(t('notifications.apiTab.createToken.success'), { variant: 'success' })
        await refetchTokens()
      },
      onError: () => {
        notify(t('notifications.apiTab.createToken.error'), { variant: 'error' })
      },
    }
  )

  const deleteTokenMutation = useMutation(
    QueryKey.DeleteApiToken,
    async (token: string) => {
      await api.organizationControllerDeleteToken(token)
    },
    {
      onSuccess: async () => {
        notify(t('notifications.apiTab.deleteToken.success'), { variant: 'success' })
        await refetchTokens()
      },
      onError: () => {
        notify(t('notifications.apiTab.deleteToken.error'), { variant: 'error' })
      },
    }
  )

  const isLoading =
    isFetchingTokens || createTokenMutation.isLoading || deleteTokenMutation.isLoading

  function deleteToken(token: string) {
    setToken(token)
    openConfirm()
  }

  const DeleteTokenButton = ({ id }: { id: string }) => (
    <Tooltip title={t('administration.apiTab.table.tooltip')} placement="right">
      <StyledIconButton onClick={() => deleteToken(id)}>
        <CloseRounded sx={{ ...iconButtonSx, color: theme.colors.GREY }} />
      </StyledIconButton>
    </Tooltip>
  )

  const ConfirmContent: React.FC = () => (
    <>
      <Typography variant="body1" color="secondary" sx={{ maxWidth: '90%' }}>
        {t('administration.apiTab.confirm.helperTexts.1')}
      </Typography>
      <Typography variant="body1" color="secondary" sx={{ maxWidth: '90%' }}>
        {t('administration.apiTab.confirm.helperTexts.2')}
      </Typography>
    </>
  )

  return (
    <Column sx={{ height: '600px' }}>
      <Row sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h2" align="left" sx={{ fontWeight: 500 }}>
          {t('administration.apiTab.mainText')}
        </Typography>
        <CanAccess roles={ROLES_WITH_WRITE_PERMISSION}>
          <LoadingButton
            sx={buttonSx}
            color="primary"
            variant="contained"
            disabled={isLoading}
            startIcon={<AddCircleOutlineRounded />}
            loading={createTokenMutation.isLoading}
            onClick={async () => await createTokenMutation.mutateAsync()}>
            {t('administration.apiTab.addButton')}
          </LoadingButton>
        </CanAccess>
      </Row>
      <Typography sx={{ maxWidth: '500px', fontWeight: 300 }}>
        {t('administration.apiTab.secondaryText')}
      </Typography>
      <Spacer y={3} />
      <ApiTokensTable tokens={tokens} button={DeleteTokenButton} />
      <DisplayApiTokenDialog token={token} />
      <ConfirmDialog
        content={ConfirmContent}
        title={t('administration.apiTab.confirm.title')}
        loading={deleteTokenMutation.isLoading}
        onConfirm={() => deleteTokenMutation.mutateAsync(token)}
      />
    </Column>
  )
}

const buttonSx: SxProps = {
  width: '190px',
  height: '30px',
  fontWeight: 400,
}
const iconButtonSx: SxProps = { width: '20px', height: '20px' }

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.text.secondary,
    border: 'none',
  },
}))
