import { SnackbarProvider } from 'notistack'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'

import { AuthProvider, useAuthContext } from 'libs/auth/provider'
import { ConsentProvider } from 'libs/consent'
import { ImportUsersContextProvider } from 'libs/import/context'
import { ModalProvider } from 'libs/modal'
import { SignInPage } from 'login/SignIn'

import { CustomAdmin } from './Admin'
import { UserProvider } from './user/context'

const queryClient = new QueryClient()

const AppContent = () => {
  const { auth } = useAuthContext()

  if (!auth.isAuthenticated) {
    return <SignInPage />
  }

  return <CustomAdmin />
}

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <UserProvider>
            <ModalProvider>
              <ImportUsersContextProvider>
                <SnackbarProvider>
                  <ConsentProvider>
                    <AppContent />
                  </ConsentProvider>
                </SnackbarProvider>
              </ImportUsersContextProvider>
            </ModalProvider>
          </UserProvider>
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}

export default App
